<template>
  <div>
    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        受講生の<b>動画視聴状況</b>を表示しています。
      </div>
    </b-alert>
    <b-card
      v-for="(item, index) in categoryA"
      :key="index"
    >
      <b-card-title>
        {{ item.labelName }}
      </b-card-title>
      <b-card-text>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            商品コード
          </b-col>
          <b-col>
            {{ item.id }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            購入日
          </b-col>
          <b-col>
            {{ formatDate(showData(item).insertTime) }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            期限(6ヶ月)
          </b-col>
          <b-col>
            <div v-if="showData(item).insertTime">
              {{ showDeadLine(item) }}
              <div class="text-sub mt-1">
                <small>※購入日を基準に計算しています。</small>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            ステータス
          </b-col>
          <b-col>
            <div v-if="showData(item).insertTime">
              <div v-if="showData(item).isValid">
                <b-badge
                  v-if="showData(item).isValid.value === 1"
                  pill
                  variant="success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  有効
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="secondary"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  無効
                </b-badge>
              </div>
              <div class="text-sub mt-1">
                <small>※｢有効｣の場合のみ、動画を視聴できます。</small>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <div class="text-right">
          <b-button
            variant="outline-primary"
            size="sm"
            class="btn-wishlist"
            @click="editSetting(item)"
          >
            <feather-icon
              icon="SettingsIcon"
              class="mr-50"
            />
            <span>設定する</span>
          </b-button>
        </div>
      </b-card-text>
    </b-card>
    <div class="text-sub mt-2">
      <small>※サイト外で決済を済ませた場合は、決済日を手動で入力します。</small>
    </div>
    <div class="text-sub mb-2">
      <small>※｢ステータス｣を有効にすることで継続して視聴できるようになり、無効にすると動画を視聴できなくなります。</small>
    </div>
    <b-modal
      v-model="isShowModal"
      size="lg"
      title=""
      ok-only
      ok-variant="flat-secondary"
      ok-title="閉じる"
    >
      <EditVideoSetting
        :lesson-dic="lessonDic"
        :user-dic="dataDic"
        @refetch="refetch"
        @hide="isShowModal = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BAlert, BCard, BCardText, BCardTitle, BButton, BModal, BBadge,
} from 'bootstrap-vue'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { ref, onMounted } from '@vue/composition-api'
import EditVideoSetting from '@/components/admin/student/components/EditVideoSetting.vue'
import useApply from '@/components/js/compositionAPI/apply/useApply'
import formatDate from '@/components/js/filter/formatDate'
import dayjs from 'dayjs'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BModal,
    BBadge,
    EditVideoSetting,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isShowModal = ref(false)
    const lessonDic = ref({})
    const lessonList = ref([])
    const { getApplyAllByUid } = useApply()
    const {
      categoryA,
    } = useLesson()

    const showData = item => {
      const lesson = lessonList.value.find(v => v.lessonId === item.id)
      return lesson || {}
    }

    const showDeadLine = item => {
      const lesson = lessonList.value.find(v => v.lessonId === item.id)
      const insertTime = lesson.insertTime.toDate()
      const deadline = dayjs(insertTime).add(6, 'M').subtract(1, 'day')
      return deadline.format('YYYY年MM月DD日')
    }

    const editSetting = item => {
      isShowModal.value = true
      lessonDic.value = item
    }

    const fetch = async () => {
      lessonList.value = await getApplyAllByUid({ uid: props.dataDic.uid })
    }
    const refetch = () => {
      fetch()
      emit('refetch')
    }
    onMounted(() => {
      fetch()
    })

    return {
      isShowModal,
      lessonDic,
      lessonList,
      categoryA,
      editSetting,
      showData,
      showDeadLine,
      formatDate,
      refetch,
    }
  },
}
</script>
