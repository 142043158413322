<template>
  <div class="space-md">
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <h2>受講生一覧</h2>
    <ListUser />
  </div>
</template>

<script>
import { BBreadcrumb } from 'bootstrap-vue'
import ListUser from '@/components/admin/student/components/ListUser.vue'

export default {
  components: {
    BBreadcrumb,
    ListUser,
  },
  setup() {
    const breadcrumbItems = [
      {
        text: 'ダッシュボード',
        to: { name: 'admin-dashboad' },
        icon: 'HomeIcon',
      },
      {
        text: '受講生一覧',
        active: true,
      },
    ]
    return {
      breadcrumbItems,
    }
  },
}
</script>
