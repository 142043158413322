<template>
  <ListStudent />
</template>

<script>
import ListStudent from '@/components/admin/student/ListStudent.vue'

export default {
  components: {
    ListStudent,
  },
}
</script>

<style lang="scss">
</style>
