<template>
  <div>
    <div class="d-flex">
      <h2>動画の視聴設定</h2>
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <b-avatar
            size="40"
            src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
            variant="light-primary"
            class="mr-50"
          />
          <div class="ml-50">
            動画を視聴するための権限を管理します。
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-row
        v-for="(item, index) in menulist"
        :key="index"
      >
        <InputParts
          v-if="useValueDicList.includes(item.type)"
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value-dic="inputForm[item.key]"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type ==='checkbox' ? choice: []"
          @inputData="inputForm[item.key] = $event"
        />
        <InputParts
          v-else-if="useValueListList.includes(item.type)"
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
          :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type === 'checkboxplain' ? item.select: []"
          @inputData="inputForm[item.key] = $event"
        />
        <InputParts
          v-else
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type ==='checkbox' ? choice: []"
          @inputData="inputForm[item.key] = $event"
        />
      </b-row>
      <b-row class="mt-2">
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-50"
            @click="confirm"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            />
            登録する
            <b-spinner
              v-if="status === 2"
              small
            />
          </b-button>
        </b-col>
      </b-row>
      <div v-if="errorList.length > 0">
        <b-alert
          variant="danger"
          show
          class="alertSpace"
        >
          <div class="alert-body">
            <div class="">
              {{ errorMSG }}
            </div>
            <div
              v-for="(item, index) in errorList"
              :key="index"
              class=""
            >
              ・<b>{{ item }}</b>
            </div>
          </div>
        </b-alert>
      </div>
    </b-card>
    <SuccessBasic
      :show-alert="isShowModal"
      @refetch="hide"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import SuccessBasic from '@/components/ui/alert/SuccessBasic.vue'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
import { ref, onMounted } from '@vue/composition-api'
import useData from '@/components/js/compositionAPI/data/useData'
import useApply from '@/components/js/compositionAPI/apply/useApply'
import menulist from './動画視聴設定.json'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BAvatar,
    SuccessBasic,
  },
  directives: {
    Ripple,
  },
  props: {
    lessonDic: {
      type: Object,
      default: () => {},
    },
    userDic: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const inputForm = ref({})
    const status = ref(1)
    const isShowModal = ref(false)
    const useValueDicList = ['checkbox', 'selectSingle', 'cancelPolicy', 'radio']
    const useValueListList = ['checkboxplain', 'qualificationInput', 'serviceCategory', 'languageLevelSelect']
    const errorMSG = ref('')
    const errorList = ref([])
    const { updateApplyByAdmin, getApplySingleByUid } = useApply()
    const { isInvalid } = useData()

    const confirm = async () => {
      status.value = 2
      inputForm.value.lessonId = props.lessonDic.id
      inputForm.value.lessonInfo = props.lessonDic
      inputForm.value.uid = props.userDic.uid
      const d = {
        menulist,
        inputForm: inputForm.value,
      }
      const validation = isInvalid(d)
      if (validation.isInvalid) {
        errorMSG.value = validation.errorMSG
        errorList.value = validation.errorList
      } else {
        if (!inputForm.value.uid) inputForm.value.uid = props.userDic.uid
        const res = await updateApplyByAdmin({ data: inputForm.value })
        if (res.status === 'success') {
          isShowModal.value = true
          emit('refetch')
        }
      }
      status.value = 1
    }
    const fetch = async () => {
      const d = {
        uid: props.userDic.uid,
        lessonId: props.lessonDic.id,
      }
      inputForm.value = await getApplySingleByUid(d)
    }
    onMounted(() => {
      fetch()
    })
    const hide = () => {
      emit('hide')
    }
    return {
      inputForm,
      status,
      isShowModal,
      menulist,
      choice,
      useValueDicList,
      useValueListList,
      errorMSG,
      errorList,
      confirm,
      hide,
    }
  },
}
</script>
