import firebase from 'firebase/app'
import store from '@/store'

export default class CreateOrUpdateItem {
  constructor(payload) {
    this.collection = payload.collection
    this.data = payload.data
  }

  async update() {
    try {
      this.data.updateTime = new Date()
      this.data.updateUid = store.state.userBasic.uid
      this.data.updateMail = store.state.userBasic.mail

      await firebase.firestore().collection(this.collection).doc(this.data.docID).set(this.data, { merge: true })

      return { status: 'success' }
    } catch (error) {
      window.console.log('error', error)
      return { status: 'error', msg: error }
    }
  }

  async create() {
    try {
      const promise = await firebase.firestore().collection(this.collection).doc()

      this.data.docID = promise.id
      this.data.uid = store.state.userBasic.uid
      this.data.mail = store.state.userBasic.mail
      this.data.insertTime = new Date()
      this.data.displayStatus = 'active'

      await firebase.firestore().collection(this.collection).doc(this.data.docID).set(this.data, { merge: true })

      return { status: 'success', data: this.data }
    } catch (error) {
      window.console.log('error', error)
      return { status: 'error', msg: error }
    }
  }

  async execute() {
    if (this.data.docID) {
      const res = await this.update()
      return res
    }
    const res = await this.create()
    return res
  }
}
