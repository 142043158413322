import CreateOrUpdateItem from '@/firestore/data/CreateOrUpdateItem'
import GetDataListWhereKeyValue from '@/firestore/data/GetDataListWhereKeyValue'
import UpdateSingleData from '@/firestore/data/UpdateSingleData'
import store from '@/store'

export default function useData() {
  /**
   * 入力フォームにおける入力必須の確認
   * 未記入や一度入力した後の削除などを検証する
   * @param {Object} payload
   * @returns {Boolean} true：エラーあり
   */
  const isInvalid = payload => {
    const errorList = []
    const datakeys = Object.keys(payload.inputForm)
    payload.menulist.forEach(item => {
      if (item.required && !datakeys.includes(item.key)) {
        errorList.push(item.label)
      }
      if (item.required && typeof payload.inputForm[item.key] === 'object') {
        if (Object.keys(payload.inputForm[item.key]).length === 0) errorList.push(item.label)
      }
    })
    if (errorList.length > 0) {
      const errorMSG = '下記の項目を入力してください。'
      return { isInvalid: true, errorMSG, errorList }
    }
    return { isInvalid: false }
  }

  const createOrUpdateSingleItem = async item => {
    const target = new CreateOrUpdateItem(item)
    const res = await target.execute()
    return res
  }

  /**
   * 指定するcollectionにおいてuidで1件のDictionaryを取得する。
   * @param {Object} collection
   * @returns {Object}
   */
  const getSingleItemByUid = async item => {
    const single = new GetDataListWhereKeyValue()
    const d = {
      collection: item.collection,
      targetKey: 'uid',
      targetValue: store.state.userBasic.uid,
    }
    const res = await single.fetch(d)
    return res.data[0]
  }

  const getSingleItemByDocID = async item => {
    const single = new GetDataListWhereKeyValue()
    const d = {
      collection: item.collection,
      targetKey: 'docID',
      targetValue: item.docID,
    }
    const res = await single.fetch(d)
    return res.data[0]
  }

  const getDataListDisplayStatus = async item => {
    const multi = new GetDataListWhereKeyValue()
    const d = {
      collection: item.collection,
      targetKey: 'displayStatus',
      targetValue: true,
    }
    const res = await multi.fetch(d)
    return res.data
  }

  const sortItemByTimestamp = (a, b) => {
    const A = a.insertTime.toDate()
    const B = b.insertTime.toDate()
    return A - B
  }

  const updateSingleItemByDocID = async payload => {
    const singleData = new UpdateSingleData()
    const res = await singleData.update(payload)
    return res
  }

  return {
    isInvalid,
    createOrUpdateSingleItem,
    getSingleItemByUid,
    getSingleItemByDocID,
    updateSingleItemByDocID,
    getDataListDisplayStatus,
    sortItemByTimestamp,
  }
}
