import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import GetDataListWhereKeyValue from '@/firestore/data/GetDataListWhereKeyValue'
import UpdateSingleData from '@/firestore/data/UpdateSingleData'
import SingleCreate from '@/firestore/apply/SingleCreate'

export default function useApply() {
  const {
    sortItemByLessonId,
  } = useLesson()

  const createItem = async payload => {
    const sigle = new SingleCreate()
    const res = await sigle.create(payload)
    return res
  }
  const updateItem = async payload => {
    const sigle = new UpdateSingleData()
    const res = await sigle.update(payload)
    return res
  }
  const updateApplyByAdmin = async payload => {
    const data = { ...payload.data }
    if (!data.payment) data.payment = 'admin'
    const d = {
      collection: 'apply',
      data,
    }
    if (data.docID) {
      const res = await updateItem(d)
      return res
    }
    const res = await createItem(d)
    return res
  }
  const getAllApply = async () => {
    const d = {
      collection: 'apply',
      targetKey: 'displayStatus',
      targetValue: 'active',
    }
    const single = new GetDataListWhereKeyValue()
    const res = await single.fetch(d)
    return res.data || []
  }
  const getApplyAllByUid = async payload => {
    const d = {
      collection: 'apply',
      targetKey: 'uid',
      targetValue: payload.uid,
    }
    const single = new GetDataListWhereKeyValue()
    const res = await single.fetch(d)
    if (res.data.length > 1) res.data.sort((a, b) => sortItemByLessonId(a, b))
    return res.data || {}
  }
  const getApplySingleByUid = async payload => {
    const res = await getApplyAllByUid(payload)
    const apply = res.find(v => v.lessonInfo.id === payload.lessonId)
    return apply || {}
  }
  return {
    updateApplyByAdmin,
    getAllApply,
    getApplyAllByUid,
    getApplySingleByUid,
  }
}
