import GetDataListWhereKeyValue from '@/firestore/data/GetDataListWhereKeyValue'
import useData from '@/components/js/compositionAPI/data/useData'
import store from '@/store'

export default function useProfile() {
  const {
    sortItemByTimestamp,
    createOrUpdateSingleItem,
  } = useData()

  const getProfilesByUserId = async userIds => {
    const dataList = []
    /* eslint-disable */
    for (let i = 0 ; i < userIds.length ; i += 1) {
      const userId = userIds[i]
      const d = {
        collection: 'profile',
        targetKey: userId.type,
        targetValue: userId.id,
      }
      const single = new GetDataListWhereKeyValue()
      const res = await single.fetch(d)
      dataList.push(res.data[0])
    }
    /* eslint-enable */
    return dataList
  }

  const getProfileSingleByUid = async () => {
    const d = {
      collection: 'profile',
      targetKey: 'uid',
      targetValue: store.state.userBasic.uid,
    }
    const single = new GetDataListWhereKeyValue()
    const res = await single.fetch(d)
    return res.data[0] || {}
  }
  const getAllProfiles = async () => {
    const d = {
      collection: 'profile',
      targetKey: 'displayStatus',
      targetValue: 'active',
    }
    const single = new GetDataListWhereKeyValue()
    const res = await single.fetch(d)
    const dataList = res.data || []
    if (dataList.length > 1) dataList.sort((a, b) => sortItemByTimestamp(a, b)).reverse()
    return dataList
  }

  const updateProfile = async payload => {
    const data = { ...payload.data }
    if (!data.isProfile) data.isProfile = true

    const d = {
      collection: 'profile',
      data,
    }
    const res = await createOrUpdateSingleItem(d)
    return res
  }

  return {
    getProfilesByUserId,
    getProfileSingleByUid,
    getAllProfiles,
    updateProfile,
  }
}
