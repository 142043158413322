<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <b-avatar
            size="40"
            src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
            variant="light-primary"
            class="mr-50"
          />
          <div class="ml-50">
            受講生の情報を表示しています。
          </div>
        </div>
      </div>
    </b-alert>
    <Display
      :data-list="profileList"
      :user-list="userList"
      @child-action="refetch"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAlert, BAvatar,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useProfile from '@/components/js/compositionAPI/profile/useProfile'
import Display from './Display.vue'

export default {
  components: {
    BAlert,
    BAvatar,
    Display,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const profileList = ref([])
    const userList = ref([])
    const {
      getAllProfiles,
    } = useProfile()

    const fetch = async () => {
      profileList.value = await getAllProfiles()
    }
    const refetch = () => {
      fetch()
    }

    onMounted(() => {
      fetch()
    })
    return {
      profileList,
      userList,
      refetch,
    }
  },
}
</script>
