<template>
  <div>
    <b-list-group>
      <b-list-group-item
        active
      >
        <div class="d-flex justify-content-between">
          <div class="">
            ユーザ一覧
          </div>
          <div class="">
            全 {{ dataList.length }} 件
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in dataList"
        :key="index"
      >
        <b-row>
          <!-- <b-col lg="1" cols="2">
          </b-col> -->
          <b-col lg="6">
            <div class="d-flex">
              <b-avatar
                size="28"
                :variant="`light-${getColor(index)}`"
              >
                {{ String(item.name).substring(0, 1).toUpperCase() }}
              </b-avatar>
              <div class="ml-1 text-font-size-md text-bold">
                {{ item.name }}({{ item.nameKana }}) 様
              </div>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="">
              {{ item.mail }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-50">
          <b-col class="text-right">
            <span
              v-for="(item2, index2) in applyList.filter(v => v.uid === item.uid)"
              :key="index2"
            >
              <b-badge
                pill
                :variant="item2.isValid.value === 1 ? 'success' : 'secondary'"
                class="mr-50"
              >
                {{ item2.lessonInfo.labelName }}
              </b-badge>
            </span>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between">
          <div class="date-text mt-1">
            <small>作成：{{ formatDateMinutes(item.insertTime) }}</small>
          </div>
          <div class="">
            <b-button
              variant="outline-primary"
              class="btn-wishlist"
              size="sm"
              @click="showModal(item)"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>詳細</span>
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div class="mt-2 text-sub">
      <div class="">
        <b-badge
          pill
          variant="success"
        >
          講座名
        </b-badge>
        購入済みで有効な講座です。動画の視聴が可能です。
      </div>
      <div class="">
        <b-badge
          pill
          variant="secondary"
        >
          講座名
        </b-badge>
        購入済みですが無効な講座です。動画の視聴はできません。
      </div>
    </div>
    <b-modal
      v-model="isShowModal"
      size="lg"
      title="受講生情報"
      ok-only
      ok-variant="flat-secondary"
      ok-title="閉じる"
    >
      <b-tabs align="right">
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span class="iconSmartPhone">プロフィール</span>
          </template>
          <Profile
            :data-dic="detailDic"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="VideoIcon" />
            <span class="iconSmartPhone">動画設定</span>
          </template>
          <VideoList
            :data-dic="detailDic"
            @refetch="refetch"
          />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BAvatar, BButton, BTabs, BTab, BBadge,
} from 'bootstrap-vue'
import formatDateMinutes from '@/components/js/filter/formatDateMinutes'
import { ref, onMounted } from '@vue/composition-api'
import useApply from '@/components/js/compositionAPI/apply/useApply'
import Profile from './Profile.vue'
import VideoList from './VideoList.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BButton,
    BTabs,
    BTab,
    BBadge,
    Profile,
    VideoList,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const colors = ['primary', 'secondary', 'success', 'warning', 'danger', 'info']
    const getColor = index => {
      const m = index % colors.length
      return colors[m]
    }
    const isShowModal = ref(false)
    const detailDic = ref({})
    const userDic = ref({})
    const applyList = ref([])
    const { getAllApply } = useApply()
    const showModal = async item => {
      isShowModal.value = true
      detailDic.value = props.dataList.find(v => v.docID === item.docID)
      // const d = {
      //   collection: 'user',
      //   docID: detailDic.value.uid,
      // }
      // const sigleDataByDocID = new GetSigleDataByDocID()
      // const res = await sigleDataByDocID.fetch(d)
      // userDic.value = props.userList.find(v => v.uid === item.uid)
    }
    const statusMenu = [
      { labelName: 'プロフ作成済み', status: 'profile' },
      { labelName: 'ヒアリング準備中', status: 'hearing' },
      { labelName: '提案準備中', status: 'proposition' },
      { labelName: '面接準備中', status: 'contact' },
      { labelName: '面接決定', status: 'interview' },
      { labelName: '完了', status: 'done' },
    ]
    const statusColor = ['success', 'primary', 'warning', 'info', 'danger', 'secondary']
    const getStatusColor = status => {
      const index = statusMenu.findIndex(v => v.status === status)
      const m = index % colors.length
      return statusColor[m]
    }
    const childAction = () => {
      emit('child-action')
    }
    const fetch = async () => {
      applyList.value = await getAllApply()
    }
    const refetch = () => {
      fetch()
    }
    onMounted(() => {
      fetch()
    })
    return {
      formatDateMinutes,
      getColor,
      showModal,
      isShowModal,
      detailDic,
      userDic,
      getStatusColor,
      childAction,
      applyList,
      refetch,
    }
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.date-text {
  color: rgb(173, 173, 173);
}
</style>
