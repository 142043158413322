import firebase from 'firebase/app'

export default class SingleCreate {
  constructor() {
    this.label = ''
  }

  async create(payload) {
    const { collection, data } = payload
    try {
      const promise = await firebase.firestore().collection(collection).doc()
      data.docID = promise.id
      data.displayStatus = 'active'
      data.insertTime = new Date()
      await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
